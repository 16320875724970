import { BrowserRouter, Routes as BrowserRoutes, Route } from "react-router-dom";
import Report from "./pages/Report";
import HomePage from "./pages/Home";

export const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="/reports" element={<Report />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};
