import { ReportTrackerEntry } from "../../types/api";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";
import { ConvertDateFormat } from "../../helper/formatting";
import { Stack } from "@chakra-ui/react";
import ReportLegend from "./Legend";

type ReportsListProps = { reports: ReportTrackerEntry[]; date: Date };

const outputHTML = (status: string) => {
  if (status === "on time") {
    return <CheckCircleIcon color="green.500" />;
  } else if (status === "late") {
    return <CheckCircleIcon color="yellow.500" />;
  } else {
    return <CloseIcon color="red.500" />;
  }
};

const ReportsList = ({ reports, date }: ReportsListProps) => {
  return (
    <div>
      <Stack spacing={8} direction={"row"}>
        <Box padding={4} shadow={"md"} borderWidth={"1px"} bg="gray.100" maxW="3xl">
          <h2>{ConvertDateFormat(date)} </h2>
          <TableContainer>
            <Table variant="striped">
              <TableCaption> Report Status Tracker for all locations. La Parrilla</TableCaption>
              <Thead>
                <Tr>
                  <Th> RESTAURANT </Th>
                  <Th>PRIOR DAY CLOSING </Th>
                  <Th>OPENING </Th>
                  <Th>CHANGE </Th>
                </Tr>
              </Thead>
              <Tbody>
                {reports &&
                  reports.map((report, index) => {
                    return (
                      <Tr key={index}>
                        <Td> {report.storeName} </Td>
                        <Td> {outputHTML(report.closing)} </Td>
                        <Td> {outputHTML(report.opening)} </Td>
                        <Td>{outputHTML(report.change)}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <ReportLegend />
      </Stack>
    </div>
  );
};

export default ReportsList;
