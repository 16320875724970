import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

export type NotificationPropsType = {
  title: string;
  body: string;
  status: StatusType;
  duration: number;
  isClosable: boolean;
};

export type StatusType = "info" | "warning" | "success" | "error" | "loading";

const ShowNotification = ({ title, body, status, duration, isClosable }: NotificationPropsType) => {
  const toast = useToast();

  useEffect(() => {
    toast({
      title,
      description: body,
      status,
      duration,
      isClosable,
    });
  }, [toast, title, body, status, duration, isClosable]);
  return null;
};

export default ShowNotification;
