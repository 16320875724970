import { StoreDetails } from "../data/Store";
import { ReportEntries, ReportTrackerEntry } from "../types/api";

const formatReportData = (data: ReportEntries): ReportTrackerEntry[] => {
  return StoreDetails.map((store) => {
    const reportEntries = data.filter((entry) => entry.store_location === store.id);

    if (reportEntries.length > 0) {
      const latestEntries: any = {}; // TODO: type this

      // iterate through entries and get the latest for each type
      reportEntries.forEach((entry) => {
        const type = entry.report_type;
        if (!latestEntries[type] || entry.date_created > latestEntries[type].date_created) {
          latestEntries[type] = entry;
        }
      });

      return {
        ...store,
        opening: latestEntries["opening_entry"]
          ? latestEntries["opening_entry"].status
          : STATUS.NOT_REPORTED,
        closing: latestEntries["closing_entry"]
          ? latestEntries["closing_entry"].status
          : STATUS.NOT_REPORTED,
        change: latestEntries["change_entry"]
          ? latestEntries["change_entry"].status
          : STATUS.NOT_REPORTED,
      };
    }

    return {
      ...store,
      opening: STATUS.NOT_REPORTED,
      closing: STATUS.NOT_REPORTED,
      change: STATUS.NOT_REPORTED,
    };
  });
};

const STATUS = {
  ON_TIME: "on time",
  LATE: "late",
  NOT_REPORTED: "not reported",
};

export default formatReportData;
