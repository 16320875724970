import axios from "axios";

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.message === "Network Error") {
//       console.log("Network Error");
//       window.location.href = "/";
//       return Promise.reject({ message: "Network Error" });
//     }
//   }
// );

export default Api;
