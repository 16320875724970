import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Box } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

const ReportLegend = () => {
  return (
    <Box padding={4} shadow={"md"} borderWidth={"1px"} h={"30%"} bg="white.100" maxW="3xl">
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th> Icons </Th>
              <Th> </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                {" "}
                <CheckCircleIcon color="green.500" />{" "}
              </Td>
              <Td> ON TIME </Td>
            </Tr>

            <Tr>
              <Td>
                {" "}
                <CheckCircleIcon color="yellow.500" />{" "}
              </Td>
              <Td> LATE </Td>
            </Tr>

            <Tr>
              <Td>
                {" "}
                <CloseIcon color="red.500" />{" "}
              </Td>
              <Td> NOT SUBMITTED </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ReportLegend;
