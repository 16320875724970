import { useEffect, useState } from "react";
import { ReportTrackerEntry } from "../types/api";
import moment from "moment";
import Api from "../api";
import formatReportData from "../helper/utils";

const convertedDate = (date: Date) => moment(date).format("YYYY-MM-DD");

const useReportsTrackerData = () => {
  const [reports, setReports] = useState<ReportTrackerEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState<string>(convertedDate(moment().toDate()));
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    if (selectedDate !== "") {
      try {
        fetchReports(selectedDate);
      } catch (error) {
        console.error("An error occured", error);
      }
    }
  }, [selectedDate]);

  const fetchReports = async (selectedDate: string) => {
    try {
      Api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.message === "Network Error") {
            setNetworkError(true);
            console.log("Network Error");
            // window.location.href = "/";
            return Promise.reject({ message: "Network Error" });
          }
        }
      );

      const response = await Api.get("/reports", { params: { date: selectedDate } });
      const formattedReport: ReportTrackerEntry[] = formatReportData(response.data.data);
      setReports(formattedReport);
      setLoading(false);
    } catch (error) {
      console.error("Another error occured", error);
    }
  };

  const handleDateChange = (date: Date): any => {
    setSelectedDate(convertedDate(date));
  };

  return { selectedDate, reports, loading, setLoading, handleDateChange, networkError };
};

export default useReportsTrackerData;
