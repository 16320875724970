import React, { HTMLAttributes } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";

interface Props {
  isClearable?: boolean;
  onChange: (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => any;
  onSelect?: (date: Date, event: React.SyntheticEvent<any, Event> | undefined) => void;
  selectedDate: Date | undefined;
  showPopperArrow?: boolean;
  maxDate?: Date;
}

const DatePicker = ({
  selectedDate,
  isClearable = false,
  showPopperArrow = true,
  maxDate,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  return (
    <ReactDatePicker
      selected={selectedDate}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      maxDate={maxDate}
      {...props}
    />
  );
};

export default DatePicker;
