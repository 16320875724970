import DatePicker from "../components/Datepicker/Datepicker";
import LoadingPanel from "../components/LoadingPanel";
import ReportsList from "../components/Reports/List";
import useReportsTrackerData from "../hooks/useReportsTrackerData";
import { Box, Container } from "@chakra-ui/react";
import moment from "moment";
import { formatDateStringToDate } from "../helper/formatting";
import ShowNotification from "../helper/notify";

type DateInputType = Date | React.SyntheticEvent<any, Event> | undefined;

const Report = () => {
  const { reports, loading, setLoading, selectedDate, handleDateChange, networkError } =
    useReportsTrackerData();

  const handleDatePickerChange = (date: DateInputType): any => {
    setLoading(true);
    handleDateChange(date as Date);
  };

  return (
    <div>
      {networkError && (
        <ShowNotification
          title="Network Error"
          body="Please check your internet connection. This might also be a server error. Please try again later"
          status="error"
          duration={5000}
          isClosable={true}
        />
      )}
      <Container maxW="container.3xl" centerContent marginTop={30}>
        <h1>Reports Status Tracker </h1>
      </Container>

      <Container maxW="container.3xl" centerContent marginTop={30}>
        <h4> Choose a different date </h4>
        <Box padding={4} bg="gray.100" maxW="3xl">
          <DatePicker
            id="published-date"
            selectedDate={formatDateStringToDate(selectedDate)}
            onChange={handleDatePickerChange}
            showPopperArrow={true}
            maxDate={moment().toDate()}
          />
        </Box>
      </Container>

      <Container maxW="container.3xl" centerContent marginTop={30}>
        {loading ? (
          <LoadingPanel />
        ) : (
          <ReportsList reports={reports} date={formatDateStringToDate(selectedDate)} />
        )}
      </Container>
    </div>
  );
};

export default Report;
