import moment from "moment";
import "moment-timezone";

type DateType = string | Date;

export const ConvertDateTimeFormat = (dateTime: DateType) => {
  return moment(dateTime).format("MM-DD-YYYY HH:mm:ss");
};

export const ConvertDateFormat = (date: DateType) => {
  return date ? moment(new Date(date)).format("LL") : "";
};

export const ConvertDateFormatForHuman = (date: DateType) => {
  return date ? moment(date).fromNow() : "";
};

export const formatDateStringToDate = (date: string): Date => {
  return moment(date).toDate();
};
